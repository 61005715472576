import { RateMode } from '../stores/paramsStore';
import { api } from '../utils/api';

type GuestBookingParams = {
  first_name: string;
  last_name: string;
  middle_name?: string;
  phone: string;
  email: string;
};

type ReservationParams = {
  room_type_id: number;
  rate_id: number;
  price: number;
  arrival: string; // yyyy-MM-dd
  departure: string; // yyyy-MM-dd
  rooms: {
    adults: number;
    childs: { id: number; count: number; code: string }[];
  }[];
  payment?: {
    id: number;
    payment_type: string;
    payment_alias: string;
    part: null | number;
    is_not_pay: boolean;
    hotel: number;
  };
  notes?: { id: number; code: string; text: string }[];
  promo_code: string;
  rate_flow: RateMode;
  services?: [];
};

type BookingParams = {
  hotel_id: number;
  guest: GuestBookingParams;

  payment: {
    id: number;
    payment_type: string;
    payment_alias: string;
    part: null | number;
    is_not_pay: boolean;
    hotel: number;
  };
  rooms: { adults: number; childs: { id: number; code: string; count: number }[] }[];

  arrival: string;
  departure: string;
  room: { room_code: string; id: number };
  rate: { rate_code: string; id: number; total_price: number };
  comment?: string;
  guests?: [] | null;
  promo_code: string;
  rate_flow: RateMode;
};

type BodyRequestParams = {
  guest: GuestBookingParams;
  reservation: ReservationParams;
  guests: null;
};

export async function submitBooking(bookingParams: BookingParams) {
  const { hotel_id } = bookingParams;
  const { guest, rooms, payment, arrival, departure, room, rate, promo_code, rate_flow } =
    bookingParams;

  const url = `/api/v1/reservation/${hotel_id}/`;

  const bodyRequest: BodyRequestParams = {
    guest: {
      first_name: guest.first_name,
      last_name: guest.last_name,
      middle_name: guest.middle_name,
      phone: guest.phone,
      email: guest.email,
    },
    reservation: {
      room_type_id: room.id,
      rate_id: rate.id,
      price: rate.total_price,
      arrival: arrival,
      departure: departure,
      rooms: rooms,
      payment,
      notes: [],
      promo_code: promo_code,
      rate_flow: rate_flow,
      services: [],
    },
    guests: null,
  };

  const response = await api.post<{
    id: number;
    profile: null;
    guestnum: null;
    rate: string;
    room: string;
    redirect_url: string;
    sync_with_PMS: boolean;
  }>(url, bodyRequest);
  return { payload: response };
}
