import { useRouteError } from 'react-router-dom';
import { NotFoundPlug } from '../components/Plug/NotFoundPlug';

type RouteError = {
  statusText: string;
  message: string;
};

export const ErrorPage = () => {
  const error = useRouteError();

  return (
    <div className="container max-w-[870px] mx-auto mt-10">
      <NotFoundPlug title="Страница не найдена" text={(error as RouteError).statusText || (error as RouteError).message} />
    </div>
  );
};
