import { makeAutoObservable } from 'mobx';
import { RootStore } from './rootStore';
import { HotelConfig, getConfig } from '../apiMethods/getConfig';

export class HotelConfigStore {
  root: RootStore;
  config: HotelConfig | undefined;

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this);
  }

  init() {
    this.loadConfig();
  }

  setConfig(config: HotelConfig) {
    this.config = config;
  }

  async loadConfig() {
    const config = await getConfig();
    config && this.setConfig(config);
  }

  get hasPromocode() {
    return this.config?.promo_code;
  }

  get hotelId() {
    return this.config?.id;
  }

  get rulesOfPurchase() {
    return this.config?.rules_purchasing_voucher;
  }

  get childCategories() {
    return this.config?.hotel_child_categories.map(x => ({ id: x.id, code: x.code, count: 0, use_in_calc: x.use_in_culc }));
  }

  get paymentMethods() {
    if (this.root.paramsStore.nightsCount === 1) {
      // да, окончание на *gth, такое значение приходит с сервера
      const FIRST_NIGHT_PAYMENT_ALIAS = 'FIRST_NIGTH'
      return this.config?.hotel_payment_methods.filter(pm => pm.payment_alias !== FIRST_NIGHT_PAYMENT_ALIAS);
    }
    return this.config?.hotel_payment_methods;
  }

  get policyLink() {
    return this.config?.users_term;
  }

  get rulesAndServicesLink() {
    return this.config?.rules_and_services;
  }

  get shoudSkipServices() {
    return !this.config?.use_packages;
  }

  get bookingCancelTypes() {
    return this.config?.hotel_reservation_cancel_types;
  }
}
