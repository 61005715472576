import { Button } from './shared/Button/Button';
import { Dialog } from './shared/Dialog/Dialog';
import { HeadingUI2 } from './shared/Heading';
import CloseIcon from '../assets/close-icon.svg?react';
import { useRootStore } from '../stores/useRootStore';
import { observer } from 'mobx-react-lite';

export const ErrorModal = observer(() => {
  const { errorStore } = useRootStore();

  const closeErrorModal = () => {
    errorStore.clearError();
  };

  if (!errorStore.error) {
    return null;
  }

  return (
    <>
      <Dialog isOpen={true} onClose={closeErrorModal} className="!h-min">
        <div className='w-full fixed bottom-0 xl:static bg-neutral-0'>

        <div className="xl:w-[571px] p-6 pb-8 pl-8">
          <div>
            <HeadingUI2 className="pr-[52px] mb-5 font-medium">{errorStore.error.name}</HeadingUI2>
            <p className="mb-6 font-light">{errorStore.error.message}</p>
            <Button onClick={closeErrorModal} className=' w-full xl:w-auto'>Принять</Button>
          </div>
          <Button style="primary" variant="link" onClick={closeErrorModal} className="!p-[14px] absolute top-2 right-5 xl:top-6 xl:right-6">
            <CloseIcon className={``} />
          </Button>
        </div>
        </div>
      </Dialog>
    </>
  );
});
