import PlusAlternateIcon from '../assets/plus-alternate-icon.svg?react';
import MinusIcon from '../assets/minus-icon.svg?react';
import CloseIcon from '../assets/close-icon.svg?react';

import { Dialog } from '../components/shared/Dialog/Dialog';
import { useNavigate, useParams } from 'react-router-dom';
import { PriceAndButtons } from './PriceAndButtons';
import SimpleGallery from '../components/ImageGallery';
import { Button } from '../components/shared/Button/Button';
import { Heading2 } from '../components/shared/Heading';
import { useRootStore } from '../stores/useRootStore';
import { observer } from 'mobx-react-lite';
import { Details } from '../components/shared/Details/Details';
import { ReactNode } from 'react';
import HTMLParser from '../components/HTMLParser/HTMLParser';

export const RoomDetails = observer(() => {
  const navigate = useNavigate();
  const { roomId } = useParams<{ roomId: string }>();

  const { paramsStore, configStore } = useRootStore();

  const room = paramsStore.getRoom(Number(roomId));

  const onClose = () => {
    navigate('/rooms');
  };

  const selectRoom = () => {
    room?.id && paramsStore.selectRoom(room.id);
    navigate('/rates');
  };

  const images =
    room?.images.map((img) => ({
      width: 1280,
      height: 864,
      thumbnailURL: img,
      largeURL: img,
    })) || [];

  if (!room) {
    return null;
  }

  const [rate] = room.rates;

  return (
    <Dialog isOpen={!!roomId} onClose={onClose}>
      <span
        className="fixed z-[1] m-3 bg-neutral-900 p-2.5 text-neutral-0 opacity-35 hover:cursor-pointer hover:opacity-65 xl:p-3.5"
        onClick={onClose}
      >
        <CloseIcon />
      </span>
      <div className="table-cell h-full xl:flex xl:flex-row">
        <div className="h-full flex-1 xl:overflow-auto">
          <div className="custom-image-container h-[240px] overflow-hidden xl:h-[440px] xl:min-w-[572px]">
            <SimpleGallery galleryID={`room-details-${roomId}-gallery`} images={images} />
          </div>

          <section className="px-5 py-5 ">
            <section className="pb-5">
              <Heading2>{room.name}</Heading2>
              <HTMLParser html={rate.long_description} />
            </section>

            {paramsStore.rateMode == 'CORP' && <CollapsibleText label="Правила приобретения путевки.">
              <HTMLParser html={configStore.rulesOfPurchase || ''}/>
            </CollapsibleText>}

            {room.room_services && (
              <CollapsibleText label="Для гостей, проживающих в отеле доступен широкий спектр услуг:">
                <HTMLParser html={room.room_services} />
              </CollapsibleText>
            )}
          </section>
        </div>
        <div className="flex h-full flex-1 flex-col xl:bg-neutral-10">
          <section className="mb-3 flex-grow bg-neutral-10 px-5 py-3">
            <Heading2 className="mt-5">О номере</Heading2>
            <div className="grid grid-cols-2 border-b border-neutral-30 py-5 font-light">
              <span>Цена</span>
              <span>{room.min_price} &#8381;</span>
            </div>
            <div className="grid grid-cols-2 border-b border-neutral-30 py-5 font-light">
              <span>Площадь</span>
              <span>{room.square} м2</span>
            </div>
            <div className="grid grid-cols-2 border-b border-neutral-30 py-5 font-light">
              <span>Вместимость</span>
              <span>{room.capacity}</span>
            </div>
            <div className="grid grid-cols-2 border-b border-neutral-30 py-5 font-light">
              <span>Вид из окна</span>
              <span>{room.view_type}</span>
            </div>
          </section>

          <div className="p-5">
            <PriceAndButtons price={room.min_price} nights={rate.nights.length}>
              <Button className="w-full px-6 xl:ml-6 xl:w-auto" onClick={selectRoom}>
                Забронировать
              </Button>
            </PriceAndButtons>
          </div>
        </div>
      </div>
    </Dialog>
  );
});

const CollapsibleText = ({ label, children }: { label: string; children: ReactNode }) => {
  return (
    <div className="border-b border-neutral-40 ">
      <Details
        label={(isOpen) => (
          <div className="group flex cursor-pointer justify-between py-4 xl:py-6">
            <span className="self-center">{label}</span>
            <span className="relative">
              <MinusIcon
                className={`absolute left-0 top-0 scale-0 opacity-0 transition-all ${isOpen && 'scale-100 opacity-100'}`}
              />
              <PlusAlternateIcon
                className={`transition-all group-hover:rotate-180 ${isOpen && 'scale-0 opacity-0'}`}
              />
            </span>
          </div>
        )}
      >
        <p className="pb-4 pr-5">{children}</p>
      </Details>
    </div>
  );
};
