import { useCallback, useState } from 'react';
import { CancelData } from '../../../apiMethods/cancelBooking';
import { HotelConfig } from '../../../apiMethods/getConfig';
import { Select } from '../../../components/shared/Select';
import { WarningModal } from '../../../components/WarningModal';
import { TextAreaField } from '../../../components/shared/InputField/InputField';
import { Button } from '../../../components/shared/Button/Button';
import { useRootStore } from '../../../stores/useRootStore';
import { useParams } from 'react-router-dom';
import { RefundInfo } from '../../../apiMethods/getRefundInfo';
import { observer } from 'mobx-react-lite';
import { Skeleton } from '../../../components/Skeleton';

type CancelReason = HotelConfig['hotel_reservation_cancel_types'][0] & { label: string };

export const BookingCancellation = observer(() => {
  const { bookingId } = useParams<{ bookingId: string }>();

  const { configStore, bookingsStore } = useRootStore();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onBookingModalOpen = () => {
    setIsModalOpen(true);
    const hotelId = configStore.hotelId;
    if (!hotelId) {
      return;
    }
    bookingsStore.loadRefundInfo(hotelId, Number(bookingId));
  };

  const onBookingCancel = async (cancelData: CancelData) => {
    if (!configStore.hotelId || !bookingId) {
      return;
    }

    await bookingsStore.cancelBooking({
      hotelId: configStore.hotelId,
      bookingId: Number(bookingId),
      cancelData,
    });
    await bookingsStore.loadBookingInfo(bookingId);
  };

  const getTitle = () => {
    if (bookingsStore.refund.pending) {
      return <Skeleton className="h-[2.25rem] w-full" loading />;
    }

    return 'Вы действительно хотите отменить бронирование?';
  };

  return (
    <>
      <Button style="secondary" variant="outlined" onClick={onBookingModalOpen}>
        Отмена бронирования
      </Button>

      <WarningModal title={getTitle()} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <BookingCancellationModal
          refund={bookingsStore.refund}
          options={configStore.bookingCancelTypes?.map((o) => ({ ...o, label: o.name })) || []}
          onBookingCancel={(data) => onBookingCancel(data)}
          cancellationPending={bookingsStore.bookingCancellationPending}
          onClose={() => setIsModalOpen(false)}
        />
      </WarningModal>
    </>
  );
});

export const BookingCancellationModal = ({
  options,
  onBookingCancel,
  refund,
  cancellationPending,
  onClose
}: {
  options: CancelReason[];
  onBookingCancel: (cancelData: CancelData) => void;
  refund: { data: RefundInfo | null; pending: boolean; error: string | null };
  cancellationPending: boolean;
  onClose: () => void
}) => {
  const [cancelReason, setCancelReason] = useState<(CancelReason & { label: string }) | null>(null);
  const [comment, setComment] = useState('');

  const cancellationBlocked = cancelReason?.need_comment && !comment;

  const onCancelReasonChange = useCallback(
    (index: number | null) => {
      const reason = index !== null && options[index];
      reason && setCancelReason({ ...reason, label: reason.name });
    },
    [options],
  );

  const onCancelBooking = () => {
    if (cancellationBlocked) {
      return;
    }
    onBookingCancel({
      cancel_type_id: Number(cancelReason?.id),
      cancel_comment: comment,
    });
  };

  if (refund.pending) {
    return (
      <div>
        <div>
          <Skeleton className="mb-1 h-4" loading></Skeleton>
          <Skeleton className="mb-1 h-4" loading></Skeleton>
          <Skeleton className="mb-1 h-4" loading></Skeleton>
          <Skeleton className="mb-1 h-4 w-3/4" loading></Skeleton>
        </div>

        <div className="mt-4">
          <Skeleton className="mb-1 h-4" loading></Skeleton>
          <Skeleton className="mb-1 h-4" loading></Skeleton>
          <Skeleton className="mb-1 h-4 w-3/4" loading></Skeleton>
        </div>

        <div className="mt-8 flex gap-4">
          <Skeleton className="h-[50px] w-[100px]" loading></Skeleton>
          <Skeleton className="h-[50px] w-[200px]" loading></Skeleton>
        </div>
      </div>
    );
  }

  return (
    <>
      <Select
        options={options}
        placeholder="Причина отмены"
        value={cancelReason}
        onChange={(index) => onCancelReasonChange(index)}
      >
        {options.map((o) => (
          <Select.Option key={o.id} label={o.name} />
        ))}
      </Select>

      {cancelReason?.need_comment && (
        <div className="my-4">
          <TextAreaField
            label="Укажите вашу причину отмены"
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      )}

      <p className="mt-8 text-sm font-light">
        Расчет идет на основании даты отмены и даты заезда. Если она больше 15 суток – отмена
        бесплатная, если меньше - из возврата вычитается стоимость первой ночи по базовому
        корпоративному тарифу (со скидкой 50%). **кроме уважительных случаев (смерть, больничный)
      </p>
      <p className="mb-4 mt-4 text-sm font-light">
        В случае иных взаиморасчетов (оплаты не при бронировании, а по квитанции и тд) и в случае
        наличия уважительной причины пожалуйста, обратитесь по адресу{' '}
        <a href="mailto:sber@mriyaresort.com">sber@mriyaresort.com</a>
      </p>
      <p>{refund.data?.description}</p>

      <div className="mt-8 space-x-4">
        <Button variant="filled" style="accent" onClick={onClose}>
          Вернуться
        </Button>
        <Button
          style="secondary"
          variant="outlined"
          onClick={onCancelBooking}
          disabled={cancellationBlocked}
          pending={cancellationPending}
        >
          Отменить бронирование
        </Button>
      </div>
    </>
  );
};
