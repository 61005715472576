import { Link, LinkProps, Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom';
import RateIcon from '../../assets/rate-icon.svg?react';
import FullBoardIcon from '../../assets/full-board-icon.svg?react';
import RubleIcon from '../../assets/ruble-icon.svg?react';
import InfoIcon from '../../assets/info-icon.svg?react';
import CloseIcon from '../../assets/close-icon.svg?react';
import ArrowLeftIcon from '../../assets/arrow-left.svg?react';
import { FunctionComponent, ReactNode, SVGProps, useEffect } from 'react';
import './custom-slider.css';
import { StickyBottom } from '../../components/shared/StickyBottom/StickyBottom';
import { Button } from '../../components/shared/Button/Button';
import { Details } from '../../components/shared/Details/Details';
import { useAnimatedDialogHandler } from '../../components/shared/Dialog/useAnimatedDialogHandler';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/useRootStore';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { noun } from 'plural-ru';
import { Heading2 } from '../../components/shared/Heading';
import HTMLParser from '../../components/HTMLParser/HTMLParser';
import { useNightsCounter } from '../../utils/useNightsCounter';

import { useRedirectToHomePage } from '../../useRedirectToHomePage';

export const RatesAndServices = () => {
  const isServices = useMatch('/services');
  const { ref, isOpen, openDialog, closeDialog } = useAnimatedDialogHandler(300);

  useRedirectToHomePage();

  const navigate = useNavigate();

  const proceed = () => {
    navigate('/form');
  };

  return (
    <main className="container mx-auto max-w-[1168px]">
      <div className="xl:mt-10 xl:grid xl:grid-cols-fixed-right">
        <section className="p-5 xl:mr-6 xl:p-8 xl:pt-0">
          <Outlet />
        </section>

        <section className="hidden h-fit bg-neutral-10 p-8 xl:block">
          <OrderSummary isServices={!!isServices} />
        </section>
      </div>
      <dialog
        ref={ref}
        className={`custom-popup m-0 h-full max-h-full w-full max-w-full bg-neutral-0/0 xl:hidden ${isOpen && 'open'}`}
      >
        <div className={`fixed bottom-0 table-cell w-full max-w-full bg-neutral-0 p-5 `}>
          <OrderSummary isServices={!!isServices} onClose={closeDialog} />
        </div>
      </dialog>

      {isServices && (
        <StickyBottom className="hidden xl:block">
          <Button onClick={proceed}>Далее</Button>
        </StickyBottom>
      )}

      <StickyBottom className="xl:hidden">
        {isServices && (
          <Button onClick={proceed} className="mb-3 w-full">
            Далее
          </Button>
        )}
        <div className="flex items-center justify-center">
          <p className="mr-2 text-lg text-neutral-900" onClick={openDialog}>
            Информация о заказе
          </p>
          <InfoIcon />
        </div>
      </StickyBottom>
    </main>
  );
};

const useGuestsCounter = (adults: number, childs: number) => {
  const adultsNoun = noun(adults, 'взрослый', 'взрослых', 'взрослых');
  const childsNoun = noun(childs, 'ребёнок', 'ребёнка', 'детей');

  return `${adults} ${adultsNoun}` + (childs ? ` ${childs} ${childsNoun}` : '');
};

const formatDate = (date?: Date) => (date ? format(date, 'd MMMM yyyy', { locale: ru }) : '-');

const OrderSummary = observer(
  ({ isServices, onClose }: { isServices: boolean; onClose?: () => void }) => {
    const { paramsStore } = useRootStore();

    const { from, to } = paramsStore.appliedParams?.range || {};
    const [adults, childs] = paramsStore.countGuests(paramsStore.appliedParams?.guestRooms || []);

    const nightsStat = useNightsCounter({ from, to });
    const guestsStat = useGuestsCounter(adults, childs);

    return (
      <>
        <div className="flex items-center justify-between">
          <h4 className="text-2xl leading-[32px]">Информация о заказе</h4>
          <CloseIcon className="xl:hidden" onClick={onClose} />
        </div>
        <section>
          <h6 className="mt-11 text-[18px] leading-[28px]">Параметры</h6>
          <InfoRow label="Прибытие" value={formatDate(paramsStore.from)} />
          <InfoRow label="Выезд" value={formatDate(paramsStore.to)} />
          <InfoRow label="Проживание" value={nightsStat} />
          <InfoRow label="Гости" value={guestsStat} />

          <h6 className="mt-11 text-[18px] leading-[28px]">Номер</h6>
          <InfoRow label="Категория" value={paramsStore.room?.name || '-'} />
          <InfoRow label="Количество" value={String(paramsStore.roomsCount || '-')} />
          {paramsStore.rate && <InfoRow label="Тариф" value={paramsStore.rate.name} />}

          {isServices && (
            <>
              {/* <h5 className="mt-11 text-xl leading-[30px]">Дополнительные услуги</h5> */}
              {/* <InfoRow label="Проживание с животными" value="20000 р / 4 ночи" /> */}
            </>
          )}

          {paramsStore.totalPrice && (
            <>
              <div className="-mx-8 my-10 border-b border-neutral-40"></div>
              <InfoRow
                label="Всего"
                value={`${paramsStore.totalPrice} ${String.fromCharCode(8381)} / ${nightsStat}`}
                isTotal
              />
            </>
          )}
        </section>
      </>
    );
  },
);

export const BackLink = ({ to, children }: { to: LinkProps['to']; children: ReactNode }) => (
  <span className="flex items-center 3xl:absolute 3xl:left-[78px]">
    <ArrowLeftIcon />
    <Link to={to} className="px-2 text-lg">
      {children}
    </Link>
  </span>
);

export const Rates = observer(() => {
  const { paramsStore } = useRootStore();

  useEffect(() => {
    // Обнулить выбранный тариф при возврате на страницу с тарифами
    paramsStore.selectRate(null);
  }, [paramsStore]);

  return (
    <>
      <BackLink to="/rooms">К выбору номера</BackLink>
      <Heading2 className="mt-8">Выберите тариф</Heading2>
      <div>
        {paramsStore.room?.rates.map((rate) => (
          <Rate
            key={rate.id}
            id={rate.id}
            name={rate.name}
            description={rate.long_description}
            summary={rate.short_description}
            price={rate.total_price}
            nights={rate.nights.length}
            fullBoard={rate.full_boarding}
            cardPayment={rate.card_payment}
          />
        ))}
      </div>
    </>
  );
});

const InfoRow = ({
  label,
  value,
  isTotal,
}: {
  label: string;
  value: string;
  isTotal?: boolean;
}) => (
  <div className={`my-4 grid grid-cols-2 items-start font-light`}>
    <span className={isTotal ? 'font-medium' : ''}>{label}</span>
    <span className={isTotal ? 'font-normal' : ''}>{value}</span>
  </div>
);

const serviceDescription =
  'VIP- встреча - предоставление высочайшего сервиса, который основывается на принципах надежности, комплексности и персонального подхода. В современном мире, каждая минута на счету, воспользовавшись услугой ВИП-встречи у Вас';

export const Services = () => {
  const { configStore } = useRootStore();
  return (
    <div>
      {configStore.shoudSkipServices && <Navigate to="/form" replace />}
      <BackLink to="/rates">К выбору тарифа</BackLink>
      <Heading2 className="mt-8">Дополнительные услуги</Heading2>

      <Service name="VIP-встреча" idx={1} description={serviceDescription} />
      <Service name="VIP-встреча" idx={1} description={serviceDescription} />
      <Service name="VIP-встреча" idx={1} description={serviceDescription} />
      <Service name="VIP-встреча" idx={1} description={serviceDescription} />
    </div>
  );
};

const Rate = (props: {
  id: number;
  name: string;
  description: string;
  summary: string;
  price: number;
  nights: number;
  fullBoard: boolean;
  cardPayment: boolean;
}) => {
  const { id, name, description, summary, price, nights, fullBoard, cardPayment } = props;

  const { paramsStore } = useRootStore();
  const navigate = useNavigate();

  const selectRate = () => {
    paramsStore.selectRate(id);
    navigate('/services/');
  };

  return (
    <Template
      name={name}
      Icon={RateIcon}
      chips={
        <>
          {fullBoard && <Chip name="Полный пансион" icon={<FullBoardIcon />} />}
          {cardPayment && <Chip name="Оплата банковской картой" icon={<RubleIcon />} />}
        </>
      }
    >
      <div className="flex-1">
        <p className="mb-1 line-clamp-4 font-light">
          <HTMLParser html={summary} />
        </p>
        <Details label="Подробнее о тарифе">
          <HTMLParser html={description} />
        </Details>
      </div>

      <div className="xl:ml-6 xl:flex xl:flex-col ">
        <div className="mb-3 mt-4 xl:mb-2 xl:flex xl:flex-col xl:items-end">
          <Price>{Number(price).toLocaleString('ru')}</Price>
          <Nights>{`за ${nights} ${noun(nights, 'ночь', 'ночи', 'ночей')}`}</Nights>
        </div>
        <Button className="w-full px-6 xl:ml-6 xl:w-auto" onClick={() => selectRate()}>
          <span className="xl:hidden">Выбрать тариф</span>
          <span className="hidden xl:block">Выбрать</span>
        </Button>
      </div>
    </Template>
  );
};

const Service = (props: { idx: number; name: string; description: string }) => {
  const { name } = props;
  const selectRate = () => {};

  return (
    <Template
      name={name}
      Icon={RateIcon}
      chips={
        <>
          <Chip name="Полный пансион" icon={<FullBoardIcon />} />
          <Chip name="Оплата банковской картой" icon={<RubleIcon />} />
        </>
      }
    >
      <div className="xl:flex">
        <div>
          <p className="mb-1 line-clamp-4 font-light">
            VIP- встреча - предоставление высочайшего сервиса, который основывается на принципах
            надежности, комплексности и персонального подхода. В современном мире, каждая минута на
            счету, воспользовавшись услугой ВИП-встречи у Вас
          </p>

          <Details label="Подробнее о тарифе">
            <p>
              Проникнитесь настроением полуострова, бронируйте проживание по тарифу с включенным
              алкоголем.
            </p>
            <p>Почувствуйте Крым — теплый, ласковый, волнующий и гостеприимный.</p>
            <p>
              Забронируйте тариф с включенным алкоголем и наслаждайтесь беззаботным отдыхом в Mriya
              Resort & SPA.
            </p>
            <p>Специальное предложение не суммируется с другими спецпредложениями.</p>
            <p>Для гостей, проживающих в отеле доступен широкий спектр услуг:</p>
            <ul className="list-disc pl-4">
              <li>Открытый и крытый бассейны;Комплементарный мини-бар в номере;Винный парк;</li>
              <li>Обновленный Пляжный комплекс с современной инфраструктурой;</li>
              <li>
                Развлекательный центр Metropolis (кинотеатр и программа анимации для взрослых и
                детей);
              </li>
              <li>Тренажерный зал и групповые тренировки;</li>
              <li>Посещение Японского сада "Шесть чувств";</li>
              <li>
                Парк чудес и приключений Дримвуд (работает с 10:00 до 18:00; среда – прогулочный
                день с 10:00 до 22:00, четверг — выходной день). *График может быть изменен;
              </li>
              <li>
                Открыта зона саун и хаммама для гостей по предварительной записи. Баня Байкал,
                Ай-Петри, Алтайская работают для всех гостей по предварительной записи за
                дополнительную оплату;
              </li>
            </ul>
          </Details>
        </div>

        <div className="xl:ml-6 xl:flex xl:flex-col ">
          <div className="mb-3 mt-4 xl:mb-2 xl:flex xl:flex-col xl:items-end">
            <Price>{Number(99999999).toLocaleString('ru')}</Price>
            <Nights>{'за 4 ночи'}</Nights>
          </div>
          <Button
            variant="outlined"
            className="w-full px-6 xl:ml-6 xl:w-auto"
            onClick={() => selectRate()}
          >
            {/* <span className="xl:hidden">Выбрать тариф</span> */}
            <span className="hidden xl:block">Добавить</span>
          </Button>
        </div>
      </div>
    </Template>
  );
};

type TemplateProps = {
  name: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  chips: ReactNode;
  children: ReactNode;
};

const Template = ({ name, Icon, chips, children }: TemplateProps) => (
  <article className="mb-8 xl:mb-10">
    <div className="mb-2 flex items-center">
      <Icon className=" mr-2 text-hz-0" />
      <h3 className="text-xl leading-[30px]">{name}</h3>
    </div>

    <div className="mb-3 flex overflow-x-auto">{chips}</div>

    <div className="xl:flex xl:flex-row">{children}</div>
  </article>
);

const Chip = ({ name, icon }: { name: string; icon: ReactNode }) => (
  <span className="flex rounded-full bg-neutral-10 px-3 py-0.5 text-neutral-70">
    {icon}
    <span className="ml-1.5 text-nowrap font-light text-neutral-700">{name}</span>
  </span>
);

const Price = ({ children }: { children: ReactNode }) => (
  <span className="whitespace-nowrap text-2xl leading-8 text-neutral-900">{children} &#8381;</span>
);

const Nights = ({ children }: { children: ReactNode }) => (
  <span className="ml-2 whitespace-nowrap text-[16px] font-light leading-5 text-neutral-70">
    / {children}
  </span>
);
